<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text() {
      return this.block.fields.Text;
    },
    imageSource() {
      const img = this.block.fields.Image;
      if (!img) { return undefined; }

      return this.$toLitiumMediaUrl(img.id, {
        maxHeight: Math.ceil(img.height / 50) * 50,
      });
    },
    imageAlt() {
      const img = this.block.fields.Image;
      return img?.alt;
    },
    imageAlign() {
      return this.block.fields.ImageAlign?.value === 'Left';
    },
    wrappingElement() {
      return this.imageLink ? 'router-link' : 'div';
    },
    imageLink() {
      const { fields } = this.block;
      return fields.CompositeLink_Link || undefined;
    },
    overlays() {
      return this.block.fields.Overlays;
    },
    overlayPositions() {
      return this.overlays?.reduce((res, overlay) => {
        const positions = [];
        const posPhone = overlay.OverlayPositionPhone?.value || 'center';
        const posDesktop = overlay.OverlayPositionDesktop?.value || 'center';

        function addPosition(value) {
          if (value === 'hidden') {
            return;
          }
          if (value && positions.indexOf(value) < 0) {
            positions.push(value);
          }
        }

        addPosition(posPhone);
        addPosition(posDesktop);

        positions.forEach((pos) => {
          if (!res[pos]) {
            res[pos] = [];
          }
          const showOn = [];
          if (pos === posPhone) {
            showOn.push('phone');
          }
          if (pos === posDesktop) {
            showOn.push('desktop');
          }
          res[pos].push({
            showOn,
            overlay,
          });
        });
        return res;
      }, {});
    },
  },
  methods: {
    getOverlayClassFor(item) {
      const classes = [
        'text-with-image__overlay-item',
        `text-with-image__overlay-item--phone-text-${
          item.overlay.OverlayTextAlignmentPhone?.value || 'center'
        }`,
        `text-with-image__overlay-item--desktop-text-${
          item.overlay.OverlayTextAlignmentDesktop?.value || 'center'
        }`,
        `text-with-image__overlay-item--${this.block.systemId}`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`text-with-image__overlay-item--${device}`);
      });
      return classes;
    },
  },
};
</script>

<template>
  <div :class="['_textWithImage-container', {'_textWithImage-container--reverse': imageAlign}]">
    <div class="_textWithImage-section _textWithImage-textContainer">
      <div class="text-with-image__text" v-html="text"></div>
    </div>
    <component
      :is="wrappingElement"
      :to="imageLink"
      class="_textWithImage-section text-with-image__image-container"
    >
      <img class="text-with-image__image" :src="imageSource" :alt="imageAlt">
      <div
        v-for="(items, field) in overlayPositions"
        :key="field"
        :class="[`text-with-image__overlay--${field}`, 'text-with-image__overlay']"
      >
        <div class="text-with-image__overlay-container">
          <div
            v-for="item in items"
            :key="item"
            v-html="item.overlay.OverlayBody"
            :class="getOverlayClassFor(item)"
          ></div>
        </div>
      </div>
    </component>
  </div>
</template>

<style>
._textWithImage-container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 0 0.5rem;

  @media (--tabletAndDesktop) {
    flex-direction: row;
  }
}

._textWithImage-container._textWithImage-container--reverse {
  flex-direction: column-reverse;

  @media (--tabletAndDesktop) {
    flex-direction: row-reverse;
  }
}

._textWithImage-section {
  width: 100%;
  padding: 0 0.5rem;

  @media (--tabletAndDesktop) {
    width: 50%;
  }
}

.text-with-image__image-container {
  position: relative;
}

.text-with-image__image {
  object-position: center;
}

._textWithImage-textContainer {
  display: flex;
  align-items: center;
}

.text-with-image__text {
  width: 100%;
}

.text-with-image__overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--layout-maxWidth);
  display: flex;
  flex-direction: column;
  padding: 20px;
  pointer-events: none;
}

.text-with-image__overlay-item {
  padding: 10px;
  display: none;
  max-height: 100%;
  white-space: normal;
  pointer-events: auto;
}

.text-with-image__overlay-item h1 {
  letter-spacing: -0.3px;
  font-size: 55px;
  margin: 0;
}

.text-with-image__overlay-item h2 {
  font-size: 40px;
  margin: 0;
}

.text-with-image__overlay-item h3 {
  font-size: 30px;
  margin: 0;
}

.text-with-image__overlay-item p {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: 8px 0;
}

@media (max-width: 767px) {
  .text-with-image__overlay {
    padding: 10px;
  }

  .text-with-image__overlay-item {
    padding: 5px;
    width: 100%;
  }

  .text-with-image__overlay-item--phone {
    display: block;
  }

  .text-with-image__overlay-item--phone-text-left {
    text-align: left;
  }

  .text-with-image__overlay-item--phone-text-center {
    text-align: center;
  }

  .text-with-image__overlay-item--phone-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .text-with-image__overlay-item--desktop {
    display: block;
  }

  .text-with-image__overlay-item--desktop-text-left {
    text-align: left;
  }

  .text-with-image__overlay-item--desktop-text-center {
    text-align: center;
  }

  .text-with-image__overlay-item--desktop-text-right {
    text-align: right;
  }
}

/* Overlay positions */
.text-with-image__overlay--top {
  align-items: center;
  justify-content: flex-start;
}

.text-with-image__overlay--topleft {
  align-items: flex-start;
  justify-content: flex-start;
}

.text-with-image__overlay--topright {
  align-items: flex-end;
  justify-content: flex-start;
}

.text-with-image__overlay--left {
  align-items: flex-start;
  justify-content: center;
}

.text-with-image__overlay--right {
  align-items: flex-end;
  justify-content: center;
}

.text-with-image__overlay--center {
  align-items: center;
  justify-content: center;
}

.text-with-image__overlay--bottom {
  align-items: center;
  justify-content: flex-end;
}

.text-with-image__overlay--bottomleft {
  align-items: flex-start;
  justify-content: flex-end;
}

.text-with-image__overlay--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
